import { initializeApp } from 'firebase/app';
import { getToken, getMessaging, onMessage } from 'firebase/messaging';
import { ToastContainer, toast } from 'react-toastify';
import { ToastifyNotification } from './pages/Notifications';

const firebaseConfig = {
    apiKey: "AIzaSyBMPBJ9ekxWULix1qRfSfaTVC44fcvaqXM",
    authDomain: "employment-task-manage.firebaseapp.com",
    projectId: "employment-task-manage",
    storageBucket: "employment-task-manage.firebasestorage.app",
    messagingSenderId: "207391264875",
    appId: "1:207391264875:web:a182165aaac4e97c55612e",
    measurementId: "G-JECN6JBB5H"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
console.log("messaging", messaging);

export const getOrRegisterServiceWorker = () => {
    if ('serviceWorker' in navigator) {
        return window.navigator.serviceWorker
            .getRegistration('/firebase-push-notification-scope')
            .then((serviceWorker) => {
                console.log("serviceWorker", serviceWorker);
                if (serviceWorker) return serviceWorker;
                return window.navigator.serviceWorker.register('/firebase-messaging-sw.js', {
                    scope: '/firebase-push-notification-scope',
                });
            });
    }
    throw new Error('The browser doesn`t support service worker.');
};
// export const getFirebaseToken = async () => {
//     const permission =await  Notification.requestPermission();
//     console.log("Permission",permission);
//     let token = " ";
//     if (permission === "default") {
//         getOrRegisterServiceWorker()
//             .then((serviceWorkerRegistration) =>
//                 getToken(messaging, { vapidKey: "BARIGLIgjfN6M4XtRUYFZEsZvfkyXiha0-F0Hx2FD5J2r5BWMMdCVUe81yhes9zCLaq9GCUgUx5kFe4g1Uf2jTg", serviceWorkerRegistration })
//             )
//             .then((t) => {
//                 token = t;
//                 console.log("Firebase token :", t);
//             })
//     }
//     else if(permission === "denied") {
//         toast.error("To Receive the notifications you have to allow the the notifications"
//         );
//     }

// }

export const getFirebaseToken = async () => {
    try {
        let token = null;
        let permission = Notification.permission;

        if (permission === "default") {
            toast.info("Please allow or block notifications to proceed.");
            permission = await Notification.requestPermission();
        }
        if (permission === "granted") {
            const serviceWorkerRegistration = await getOrRegisterServiceWorker();
            token = await getToken(messaging, { vapidKey: "BARIGLIgjfN6M4XtRUYFZEsZvfkyXiha0-F0Hx2FD5J2r5BWMMdCVUe81yhes9zCLaq9GCUgUx5kFe4g1Uf2jTg", serviceWorkerRegistration });
            console.log("Firebase token:", token);
        } else if (permission === "denied") {

            toast.error("You need to allow notifications to receive updates.");
        }

        return token;
    } catch (error) {
        console.error("Error getting Firebase token:", error);
        toast.error("There was an error while fetching the token.");
    }
};


export const onForegroundMessage = () =>
    new Promise((resolve) => onMessage(messaging, (payload) => {
        console.log("payload", payload);
        toast(<ToastifyNotification title={payload?.notification?.title} body={payload?.notification?.body} />);
        resolve(payload)
    }));
