import React, { useState } from 'react';
import { Fragment, useEffect } from "react";
import { Api } from '../../ApiData';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { getFirebaseToken, onForegroundMessage } from '../../firebase';


export const ToastifyNotification = ({ title, body }) => (
    <div className="push-notification">
        <h2 className="push-notification-title">{title}</h2>
        <p className="push-notification-text">{body}</p>
    </div>
);
const Notifications = () => {
    const [showNotificationBanner, setShowNotificationBanner] = useState(Notification.permission === 'default');
    const [notifications, setNotificationsList] = useState([]);
    useEffect(() => {
        // getFirebaseToken();
        onForegroundMessage()
            .then((payload) => {
                console.log('Received foreground message: ', payload);
                const { notification: { title, body } } = payload;
                toast(<ToastifyNotification title={title} body={body} />);
            })
            .catch(err => console.log('An error occured while retrieving foreground message. ', err));
    }, []);

    const handleGetFirebaseToken = () => {
        getFirebaseToken()
            .then((firebaseToken) => {
                console.log('Firebase token: ', firebaseToken);
                if (firebaseToken) {
                    setShowNotificationBanner(false);
                }
            })
            .catch((err) => console.error('An error occured while retrieving firebase token. ', err))
    }
    const navigate = useNavigate();

    const getAllNotificationsLists = async () => {
        const apiRes = await Api?.getAllNotifications();
        if (apiRes?.status >= 200 && apiRes?.status <= 399) {
            setNotificationsList(apiRes?.data)
            console.log("notifications:", apiRes);
            console.log("all notifications :", notifications);
        }
        if (apiRes?.status >= 400 && apiRes?.status <= 500) {
            toast.error("Something went wrong");
            if (apiRes?.statusCode === 401) {
                navigate("/");
            }
        }
    }
    useEffect(() => {
        getAllNotificationsLists();
    }, []);

    return (
        <Fragment>
            <div className="notification-container">
                <h1>Notifications</h1>
                <table className="notification-table">
                    <thead>
                        <tr>
                            <th>Sr.No.</th>
                            <th>Employee Name</th>
                            <th>Title</th>
                            <th>Body</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            notifications.map((notification, index) => (
                                <tr>
                                    <td>{++index}</td>
                                    <td>{notification?.employee_name}</td>
                                    <td>{notification?.title}</td>
                                    <td>{notification?.body}</td>
                                    <td>{notification?.date}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </Fragment>

    )
}

export default Notifications;
