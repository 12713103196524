import { Fragment, useEffect, useState, useContext } from "react";

import { useNavigate, Link } from "react-router-dom";

import { Api } from "../../ApiData";
import { AppContext } from "../../context/AppContext";

const MyProfile = () => {
  const navigate = useNavigate();
  const { setEditProfileData, setInitialUserData } = useContext(AppContext);
  const [userData, setUserData] = useState(null);

  const userDetails = async () => {
    const apiRes = await Api?.getUserDetails();
    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
      setUserData(apiRes?.data[0]);
      setEditProfileData(apiRes?.data[0]);
      setInitialUserData(apiRes?.data[0]);
    }
    if (apiRes?.status >= 400 && apiRes?.status <= 500) {
      if (apiRes?.statusCode === 401) {
        navigate("/");
      }
    }
  };

  console.log("user profile data", userData);
  if(userData){
    localStorage.setItem('role', userData.role)
  }

  useEffect(() => {
    userDetails();
  }, [navigate, setEditProfileData, setInitialUserData]);

  return (
    <Fragment>
      <div className="row right-nav-section">
        <div className="col-12">
          <div className="profile_shade">
            <div className="grade_content profile_title">
              <h2>Your Profile</h2>
              <div className="edit_profile">
                <Link to="/edit-profile">
                  Edit Profile{" "}
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.375 15.75H8.085C8.20016 15.7507 8.31431 15.7286 8.42092 15.6851C8.52753 15.6415 8.62449 15.5774 8.70625 15.4963L14.7613 9.43251L17.2462 7.00001C17.3283 6.91866 17.3934 6.82189 17.4378 6.71526C17.4822 6.60864 17.5051 6.49427 17.5051 6.37876C17.5051 6.26325 17.4822 6.14888 17.4378 6.04225C17.3934 5.93563 17.3283 5.83885 17.2462 5.75751L13.5363 2.00376C13.4549 1.92175 13.3581 1.85665 13.2515 1.81223C13.1449 1.76781 13.0305 1.74493 12.915 1.74493C12.7995 1.74493 12.6851 1.76781 12.5785 1.81223C12.4719 1.85665 12.3751 1.92175 12.2937 2.00376L9.82625 4.48001L3.75375 10.5438C3.67265 10.6255 3.60849 10.7225 3.56495 10.8291C3.52141 10.9357 3.49933 11.0499 3.5 11.165V14.875C3.5 15.1071 3.59219 15.3296 3.75628 15.4937C3.92038 15.6578 4.14294 15.75 4.375 15.75ZM12.915 3.85876L15.3913 6.33501L14.1488 7.57751L11.6725 5.10126L12.915 3.85876ZM5.25 11.5238L10.4388 6.33501L12.915 8.81126L7.72625 14H5.25V11.5238ZM18.375 17.5H2.625C2.39294 17.5 2.17038 17.5922 2.00628 17.7563C1.84219 17.9204 1.75 18.1429 1.75 18.375C1.75 18.6071 1.84219 18.8296 2.00628 18.9937C2.17038 19.1578 2.39294 19.25 2.625 19.25H18.375C18.6071 19.25 18.8296 19.1578 18.9937 18.9937C19.1578 18.8296 19.25 18.6071 19.25 18.375C19.25 18.1429 19.1578 17.9204 18.9937 17.7563C18.8296 17.5922 18.6071 17.5 18.375 17.5Z"
                      fill="white"
                    />
                  </svg>
                </Link>
              </div>
              <div className="change-password">
                <Link to="/change-password">
                  Change Password <i className="fa-solid fa-lock"></i>
                </Link>
              </div>
            </div>
            <hr className="m-0" />
            <div className="row">
              <div className="col-12">
                <div className="profile_cont">
                  <div className="profile_main">
                    <div className="profile_menimg">
                      <img src={userData?.full_image} alt="profile" />
                    </div>
                    <div className="profile_inner_cont men_add">
                      <h2>First Name</h2>
                      <h4>{userData?.first_name}</h4>
                      <h2>Email Address</h2>
                      <h4>{userData?.email}</h4>
                     
                    </div>
                  </div>
                  <div className="profile_inner_cont">
                    <h2>Last Name</h2>
                    <h4>{userData?.last_name}</h4>
                    <h2>DepartmentsName</h2>
                      <h4>{userData?.departmentsName}</h4>
                  </div>

                  <div className="profile_inner_cont men _add">
                    <h2>Designation Name</h2>
                    <h4>{userData?.designationName}</h4>
                    <h2>Skype Id</h2>
                    <h4>{userData?.skype_id}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MyProfile;
