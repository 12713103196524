import { Fragment, useContext, useEffect, useState } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

import { Api } from "../../ApiData";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AddMyTask = () => {
    const navigate = useNavigate();
    const params=useParams();
    console.log("params",params);

    const [durationList, setDurationList] = useState([]);
    const [projectRoles, setProjectRoles] = useState([]);
    const [projectList, setProjectList] = useState([]);

    const [taskStatus, setTaskStatus] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const paymentType = ["Fixed", "Billing", "Inhouse", "R&D"];
    const [newTask, setNewTask] = useState({
        task_name: "",
        project_id: "",
        start_date: new Date(),
        due_date: new Date(),
        task_status_id: "",
        task_assigned_by: "",
        note: "",
        project_role: "",
        duration: "",
        employee_id:""
    })

    const [loader, setLoader] = useState(false)
    const [error, setError] = useState(false);

    const getActiveTaskStatus = async () => {
        const apiRes = await Api?.getActiveTaskStatus();
        if (apiRes?.status >= 200 && apiRes?.status <= 399) {
            setTaskStatus(apiRes?.body);
        }
        if (apiRes?.status >= 400 && apiRes?.status <= 500) {
            toast.error("Something went wrong");
            if (apiRes?.statusCode === 401) {
                navigate("/");
            }
        }
    };

console.log("duration list  ====",durationList)

    const getActiveEmployeesList = async () => {
        const apiRes = await Api?.getActiveEmployeesList();
        if (apiRes?.status >= 200 && apiRes?.status <= 399) {
            setEmployeeList(apiRes?.body);
        }
        if (apiRes?.status >= 400 && apiRes?.status <= 500) {
            toast.error("Something went wrong");
            if (apiRes?.statusCode === 401) {
                navigate("/");
            }
        }
    };


    const getActiveTaskDuration = async () => {
        const apiRes = await Api?.getActiveTaskDuration();
        if (apiRes?.status >= 200 && apiRes?.status <= 399) {
            setDurationList(apiRes?.body);
        }
        if (apiRes?.status >= 400 && apiRes?.status <= 500) {
            toast.error("Something went wrong");
            if (apiRes?.statusCode === 401) {
                navigate("/");
            }
        }
    };

    const getActiveProjectRoles = async () => {
        const apiRes = await Api?.getActiveProjectRoles();
        if (apiRes?.status >= 200 && apiRes?.status <= 399) {
            setProjectRoles(apiRes?.body);
        }
        if (apiRes?.status >= 400 && apiRes?.status <= 500) {
            toast.error("Something went wrong");
            if (apiRes?.statusCode === 401) {
                navigate("/");
            }
        }
    };

    const getActiveProjects = async () => {
        const apiRes = await Api?.getActiveProjects();
        if (apiRes?.status >= 200 && apiRes?.status <= 399) {
            setProjectList(apiRes?.body);
        }
        if (apiRes?.status >= 400 && apiRes?.status <= 500) {
            toast.error("Something went wrong");
            if (apiRes?.statusCode === 401) {
                navigate("/");
            }
        }
    };

    const handleSubmit = async () => {
        setError(true)

        if (!newTask.task_name || !newTask.project_id || !newTask.start_date || !newTask.due_date || !newTask.task_status_id || !newTask.task_assigned_by || !newTask.project_role || !newTask?.duration || (params.isMineTask!="true" && !newTask.employee_id)) {
            return;
        } else {
            if (!compareDates(newTask.start_date, newTask.due_date)) {
                toast.error("Start date must be equal to or earlier than the end date.")
                return;
            }

            setError(false)
            setLoader(true);
            let apiRes = await Api?.addMyNewTask(newTask);
            setLoader(false);
            if (apiRes?.status >= 200 && apiRes?.status <= 399) {
                if(params.isMineTask!="true"){
                    navigate("/all-tasks")
                }else{
                    navigate("/my-task-list")
                }
                toast.success(apiRes?.message)
            }
            if (apiRes?.status >= 400 && apiRes?.status <= 500) {
                toast.error(apiRes?.message);
                if (apiRes?.statusCode === 401) {
                    navigate("/");
                }
            }
        }
    };

    const compareDates = (date1, date2) => {
        const timestamp1 = new Date(date1).getTime();
        const timestamp2 = new Date(date2).getTime();
        if (timestamp1 > timestamp2 || timestamp1 == timestamp2) {
            return false;
        } else {
            return true;
        }
    }

    const changeTaskStatus = (e) => {
        setNewTask({ ...newTask, task_status_id: e.target.value })
    }

    const changePaymentType = (e) => {
        setNewTask({ ...newTask, project_role: e.target.value })
    }

    const changeDuration = (e) => {
        setNewTask({ ...newTask, duration: e.target.value })
    }

    const changeEmpH = (e) => {
        setNewTask({ ...newTask, task_assigned_by: e.target.value })
    }

    const changeEmployee = (e) => {
        setNewTask({ ...newTask, employee_id: e.target.value })
    }

    const changeProject = (e) => {
        setNewTask({ ...newTask, project_id: e.target.value })
    }


    const handleKeyPress = (event) => {
        // Allow only alphabets and certain special characters
        const regex = /^[A-Za-z.,'"\s]+$/;
        const isValidInput = regex.test(event.key);

        if (!isValidInput) {
            event.preventDefault();
        }
    };

    const navigateTo=()=>{
        console.log("params?.isMineTask",params);
        if(params?.isMineTask=="true"){
           navigate('/my-task-list')
        }else{
           navigate('/all-tasks');
        }
    }

    useEffect(() => {
        getActiveTaskStatus();
        getActiveEmployeesList();
        getActiveTaskDuration();
        getActiveProjectRoles();
        getActiveProjects();
    }, []);

    console.log("employeeList",employeeList)
    return (
        <Fragment>
            <div id="add-emp-details" className="right-nav-section">
                <div className="row">
                    <div className="col-12">
                        <div className="profile_shade">
                            <div className="grade_content profile_title">
                                <h2>{params?.isMineTask!="true" ? "Assign A Task" : "Add New Task" }</h2>
                                <Link className="back-to" to="javascript:void(0);" onClick={() => navigateTo()}>Back</Link>
                            </div>
                            <hr className="m-0" />
                            <div className="row">
                                <div className="col-12">
                                    <div className="update_feild">
                                        <div className="row align-items-center">
                                            <div className="col-lg-12">
                                                <div className="checkout_field edit_field">
                                                    <div className="row">
                                                        {params?.isMineTask!="true" && <div className="col-md-6 col-lg-3">
                                                            <div className="mb-3">
                                                                <label
                                                                    for="exampleFormControlInput1"
                                                                    className="form-label"
                                                                >
                                                                    Employee Name <span className="text-danger" style={{ "fontSize": "20px" }}>*</span>
                                                                </label>

                                                                <div className="icon">
                                                                    <svg
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z"
                                                                            stroke="#2D2926"
                                                                            stroke-width="1.5"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z"
                                                                            stroke="#2D2926"
                                                                            stroke-width="1.42857"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                                <select
                                                                    className="form-select"
                                                                    aria-label="Default select example"
                                                                    onChange={(e) => changeEmployee(e)}
                                                                    defaultValue="Select employee"
                                                                >
                                                                    <option disabled hidden>Select employee</option>
                                                                    {employeeList &&
                                                                        employeeList.map((ele) => (
                                                                            <option key={ele?.id} value={ele?.id}>
                                                                                {ele?.first_name} {ele?.last_name}
                                                                            </option>
                                                                        ))}
                                                                </select>
                                                                {error && !newTask?.employee_id && (
                                                                    <span className="text-danger">
                                                                        Please select employee
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>}
                                                        <div className="col-md-6 col-lg-3">
                                                            <div className="mb-3">
                                                                <label
                                                                    for="exampleFormControlInput1"
                                                                    className="form-label"
                                                                >
                                                                    Task Name <span className="text-danger" style={{ "fontSize": "20px" }}>*</span>
                                                                </label>
                                                                <div className="icon">
                                                                    <svg
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z"
                                                                            stroke="#2D2926"
                                                                            stroke-width="1.5"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z"
                                                                            stroke="#2D2926"
                                                                            stroke-width="1.42857"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleFormControlInput1"
                                                                    placeholder="Task Name"
                                                                    value={newTask?.task_name}
                                                                    onChange={(e) =>
                                                                        setNewTask({
                                                                            ...newTask,
                                                                            task_name: e.target.value,
                                                                        })
                                                                    }
                                                                //   onKeyPress={handleKeyPress}
                                                                />
                                                                {error && !newTask?.task_name && (
                                                                    <span className="text-danger">
                                                                        Please enter Task name
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-3">
                                                            <div className="mb-3">
                                                                <label
                                                                    for="exampleFormControlInput1"
                                                                    className="form-label"
                                                                >
                                                                    Project Name <span className="text-danger" style={{ "fontSize": "20px" }}>*</span>
                                                                </label>
                                                                <select
                                                                    className="form-select no-space"
                                                                    aria-label="Default select example"
                                                                    onChange={(e) => changeProject(e)}
                                                                    defaultValue="Select Project"
                                                                >
                                                                    <option disabled hidden>Select Project</option>
                                                                    {projectList &&
                                                                        projectList.map((project) => (
                                                                            <option key={project?.id} value={project?.id}>
                                                                                {project?.project_name}
                                                                            </option>
                                                                        ))}
                                                                </select>

                                                                {error && !newTask?.project_id && (
                                                                    <span className="text-danger">
                                                                        Please enter project name
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-3">
                                                            <div className="mb-3">
                                                                <label
                                                                    for="exampleFormControlInput1"
                                                                    className="form-label"
                                                                >
                                                                    Select Task Status <span className="text-danger" style={{ "fontSize": "20px" }}>*</span>
                                                                </label>
                                                                <select
                                                                    className="form-select no-space"
                                                                    aria-label="Default select example"
                                                                    onChange={(e) => changeTaskStatus(e)}
                                                                    defaultValue="Select Task Status"
                                                                >
                                                                    <option disabled hidden>Select Task Status</option>
                                                                    {taskStatus &&
                                                                        taskStatus.map((t_status) => (
                                                                            <option key={t_status?.id} value={t_status?.id}>
                                                                                {t_status?.task_status_title}
                                                                            </option>
                                                                        ))}
                                                                </select>
                                                                {error && !newTask?.task_status_id && (
                                                                    <span className="text-danger">
                                                                        Please select task status
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-3">
                                                            <div className="mb-3">
                                                                <label
                                                                    for="exampleFormControlInput1"
                                                                    className="form-label"
                                                                >
                                                                    Select your role in project <span className="text-danger" style={{ "fontSize": "20px" }}>*</span>
                                                                </label>
                                                                <select
                                                                    className="form-select no-space"
                                                                    aria-label="Default select example"
                                                                    onChange={(e) => changePaymentType(e)}
                                                                    defaultValue="Select role"
                                                                >
                                                                    <option disabled hidden>Select role</option>
                                                                    {projectRoles &&
                                                                        projectRoles.map((role) => (
                                                                            <option key={role?.id} value={role?.id}>
                                                                                {role?.project_role}
                                                                            </option>
                                                                        ))}
                                                                </select>
                                                                {error && !newTask?.project_role && (
                                                                    <span className="text-danger">
                                                                        Please select role
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-3">
                                                            <div className="mb-3">
                                                                <label
                                                                    for="exampleFormControlInput1"
                                                                    className="form-label"
                                                                >
                                                                    Assigned By <span className="text-danger" style={{ "fontSize": "20px" }}>*</span>
                                                                </label>

                                                                <div className="icon">
                                                                    <svg
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z"
                                                                            stroke="#2D2926"
                                                                            stroke-width="1.5"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z"
                                                                            stroke="#2D2926"
                                                                            stroke-width="1.42857"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                                <select
                                                                    className="form-select"
                                                                    aria-label="Default select example"
                                                                    onChange={(e) => changeEmpH(e)}
                                                                    defaultValue="Select employee"
                                                                >
                                                                    <option disabled hidden>Select employee</option>
                                                                    {employeeList &&
                                                                        employeeList.filter((ele) => ele?.role_name === "Team lead" || ele?.role_name === "Reporting Manager").map((ele) => (
                                                                            <option key={ele?.id} value={ele?.id}>
                                                                                {ele?.first_name} {ele?.last_name}
                                                                            </option>
                                                                        ))}
                                                                </select>
                                                                {error && !newTask?.task_assigned_by && (
                                                                    <span className="text-danger">
                                                                        Please select employee
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-3">
                                                            <div className="mb-3">
                                                                <label
                                                                    for="exampleFormControlInput1"
                                                                    className="form-label"
                                                                >
                                                                    Task Duration <span className="text-danger" style={{ "fontSize": "20px" }}>*</span>
                                                                </label>
                                                                <div className="icon">
                                                                    <svg
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z"
                                                                            stroke="#2D2926"
                                                                            stroke-width="1.5"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z"
                                                                            stroke="#2D2926"
                                                                            stroke-width="1.42857"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                                <select
                                                                    className="form-select"
                                                                    aria-label="Default select example"
                                                                    onChange={(e) => changeDuration(e)}
                                                                    defaultValue="Select task duration"
                                                                >
                                                                    <option disabled hidden>Select task duration</option>
                                                                    {durationList &&
                                                                        durationList.map((ele) => (
                                                                            <option key={ele?.id} value={ele?.id}>
                                                                                {ele?.duration_title}
                                                                            </option>
                                                                        ))}
                                                                </select>
                                                                {error && !newTask?.duration && (
                                                                    <span className="text-danger">
                                                                        Please select task duration
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                       

                                                        <div className="col-md-6 col-lg-3">
                                                            <div className="mb-3">
                                                                <label
                                                                    for="exampleFormControlInput1"
                                                                    className="form-label"
                                                                >
                                                                    Start Date <span className="text-danger" style={{ "fontSize": "20px" }}>*</span>
                                                                </label>

                                                                <DatePicker
                                                                    selected={newTask?.start_date}
                                                                    onChange={(date) => setNewTask({ ...newTask, start_date: date })}
                                                                    //   minDate={new Date()} // Disable past dates
                                                                    dateFormat="dd/MM/yyyy" // Specify the date format 
                                                                />

                                                                {error && !newTask?.start_date && (
                                                                    <span className="text-danger">
                                                                        Please select start date
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6 col-lg-3">
                                                            <div className="mb-3">
                                                                <label
                                                                    for="exampleFormControlInput1"
                                                                    className="form-label"
                                                                >
                                                                    End Date <span className="text-danger" style={{ "fontSize": "20px" }}>*</span>
                                                                </label>

                                                                <DatePicker
                                                                    selected={newTask?.due_date}
                                                                    onChange={(date) => setNewTask({ ...newTask, due_date: date })}
                                                                    minDate={new Date()} // Disable past dates
                                                                    dateFormat="dd/MM/yyyy" // Specify the date format  
                                                                />

                                                                {error && !newTask?.due_date && (
                                                                    <span className="text-danger">
                                                                        Please select due date
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6 col-lg-12">
                                                            <div className="mb-3 note_text">
                                                                <label
                                                                    for="exampleFormControlInput1"
                                                                    className="form-label"
                                                                >
                                                                    Note (Optional)
                                                                </label>
                                                                <div className="icon note_icon">
                                                                    <svg
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z"
                                                                            stroke="#2D2926"
                                                                            stroke-width="1.5"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z"
                                                                            stroke="#2D2926"
                                                                            stroke-width="1.42857"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                    </svg>
                                                                </div>


                                                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Note"></textarea>

                                                                {/* <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleFormControlInput1"
                                                                    
                                                                    value={newTask?.note}
                                                                    onChange={(e) =>
                                                                        setNewTask({
                                                                            ...newTask,
                                                                            note: e.target.value,
                                                                        })
                                                                    }
                                                                //   onKeyPress={handleKeyPress}
                                                                /> */}
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <Button
                                                        variant="success"
                                                        onClick={() => handleSubmit()}
                                                        disabled={loader}
                                                    >
                                                        {loader && <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />}
                                                        {" "}Submit
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default AddMyTask;
