import { Fragment, useEffect, useState } from "react";

import { useNavigate, Link } from "react-router-dom";
import { Form, Button, Spinner, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";

import { Api } from "../../ApiData";
import Auth from "../../auth/Auth";
import CustomInput from "../../components/CustomInput";
import { getFirebaseToken } from "../../firebase";

const Login = () => {
  const navigate = useNavigate();
  const [inputData, setInputData] = useState({
    email: "",
    pwd: "",
    fcm_token: " ",
  });
  const [passEye, setPassEye] = useState(false);
  const [error, setError] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);

  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  const loginTime = new Date().toISOString();

  useEffect(() => {
    const fetchFirebaseToken = async () => {
      const token = await getFirebaseToken();
      if (token){
        setInputData((prevData) => ({
          ...prevData,
          fcm_token: token,
        }));
        console.log("Received Firebase token:", token);
      }
    };

    fetchFirebaseToken(); // Call the method to fetch the token
  }, []);


  const handleLogin = async (e) => {
    e.preventDefault();
    const { email, pwd, fcm_token } = inputData;
    if (!email || !emailRegex.test(email) || !pwd) {
      setError(true);
    } else {
      setError(false);
      setBtnLoader(true);
      const payload = inputData;
      console.log("payload",payload);
      const apiRes = await Api.userLogin(payload);
      console.log(" apiRes  ", apiRes)
      if (apiRes?.status >= 200 && apiRes?.status <= 399) {
        localStorage.setItem("loginTime", loginTime)
        Auth.login(apiRes?.token);
        Auth.storeAdminData(apiRes?.body[0]);
        toast.success(apiRes?.message);
        setBtnLoader(false);
        navigate("/my-profile");
      }
      if (apiRes?.status >= 400 && apiRes?.status <= 500) {
        toast.error(apiRes?.message);
        setBtnLoader(false);
      }
    }
  };

  return (
    <Fragment>
      <div className="bg_main" id="login_page">
        <div className="log_bg d-block bg-white p-relative p-5 m-auto">
          <div className="login_head d-flex align-items-center">
            <i className="fa-solid fa-user"></i>
            <h2 className="ms-3 fs-3">Sign In | Employee</h2>
          </div>
          <hr />
          <Form>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label className="fw-bold">EMAIL ADDRESS</Form.Label>
              <CustomInput
                type="text"
                placeholder="Enter your email address"
                value={inputData?.email}
                onChange={(e) =>
                  setInputData({ ...inputData, email: e.target.value })
                }
              />
              {error &&
                (!inputData?.email || !emailRegex.test(inputData?.email)) && (
                  <span className="text-danger">
                    {!inputData?.email
                      ? "Please enter your email address"
                      : !emailRegex.test(inputData?.email)
                        ? "Invalid email address"
                        : ""}
                  </span>
                )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupPassword">
              <Form.Label className="fw-bold">PASSWORD</Form.Label>
              <InputGroup>
                <CustomInput
                  type={passEye ? "text" : "password"}
                  placeholder="Enter your password"
                  value={inputData?.pwd}
                  onChange={(e) =>
                    setInputData({ ...inputData, pwd: e.target.value })
                  }
                />
                <InputGroup.Text
                  onClick={() => setPassEye(!passEye)}
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className={passEye ? "far fa-eye" : "far fa-eye-slash"}
                  ></i>
                </InputGroup.Text>
              </InputGroup>
              {error && !inputData?.pwd && (
                <span className="text-danger">Please enter your password</span>
              )}
            </Form.Group>
            <div className="forget_pas">
              <Link to={"/forgot-password"} >Forgot Password?</Link>
            </div>
            <div className="sign_btn mt-4">
              <Button
                type="submit"
                variant="primary"
                disabled={btnLoader}
                onClick={(e) => handleLogin(e)}
              >
                {btnLoader ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "SIGN IN"
                )}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;;
