import { Fragment, useEffect, useState } from "react";

import { ToastContainer, Flip } from "react-toastify";

import Routers from "./Routers";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
function App() {
  return (
    <Fragment>
      <Routers />
      <ToastContainer autoClose="2000" theme="dark" transition={Flip} />
    </Fragment>
  );
}
export default App;
