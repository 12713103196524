import { Fragment, useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { Table, Modal, Button, Spinner } from "react-bootstrap";
import { Api } from "../../ApiData";
import { useNavigate } from "react-router-dom";
import ToggleButton from "react-toggle-button";
import ProfileMenImg from "../../assets/images/profilemen.png";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { usePDF } from 'react-to-pdf';

const MyTaskList = () => {
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const { toPDF, targetRef } = usePDF({ filename: 'project-report.pdf' });

  const [tasks, setTaskList] = useState([]);

  const[isLoader,setIsLoader]=useState(false)

  const getMyTaskList = async () => {
    let apiRes = await Api?.getMyTaskList();
    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
      setTaskList(apiRes?.body);
      console.log("Tasks List:",apiRes);

    }
    if (apiRes?.status >= 400 && apiRes?.status <= 500) {
      if (apiRes?.statusCode === 401) {
        navigate("/");
      }
    }
  }

  const getTimeFormat = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);

    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    };

    const formattedDate = dateTime.toLocaleDateString('en-US', options);

    console.log(formattedDate);
    return formattedDate;

  }


  const dueDateValidate = (date2) => {
    const timestamp1 = new Date().getTime();
    const timestamp2 = new Date(date2).getTime();
    console.log(timestamp1, timestamp2);
    if (timestamp1 > timestamp2) {
      console.log("YES");
      return false;
    } else {
      console.log("No");
      return true;
    }
  }
  const [taskStatusModal, setTaskStatusModal] = useState(false);

  const changeTaskStatus = (e) => {
    setTaskStatus({ ...taskStatus, selected_task_status: e.target.value })
}

const [taskStatusList, setTaskStatusList] = useState([]);
    const getActiveTaskStatus = async () => {
        const apiRes = await Api?.getActiveTaskStatus();
        if (apiRes?.status >= 200 && apiRes?.status <= 399) {
            setTaskStatusList(apiRes?.body);

            console.log("Tasks List :",apiRes);
            console.log("all Tasks :",tasks);
            
            
        }
        if (apiRes?.status >= 400 && apiRes?.status <= 500) {
            toast.error("Something went wrong");
            if (apiRes?.statusCode === 401) {
                navigate("/");
            }
        }
    };

  const openTaskStatusModal = (task_status_id, task_title, task_id) => {
      setTaskStatusModal(true)
      setTaskStatus({ ...taskStatus, current_task: task_title, selected_task_status: task_status_id, task_id: task_id })
  }
  const [taskStatus, setTaskStatus] = useState({
    current_task: "",
    selected_task_status: "",
    task_id: ""
})

  const handleTaskStatus = async () => {
    setIsLoader(true);
    const apiRes = await Api?.updateTaskStatus(taskStatus?.selected_task_status, taskStatus?.task_id);
    setIsLoader(false)
    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
        setTaskStatusModal(false)
        toast.success(apiRes?.message)
        getMyTaskList()
    }
    if (apiRes?.status >= 400 && apiRes?.status <= 500) {
        toast.error(apiRes?.message);
        if (apiRes?.statusCode === 401) {
            navigate("/");
        }
    }
}
  
  useEffect(() => {
    getMyTaskList();
    getActiveTaskStatus();
  }, [])
  console.log("tasks=== ",tasks)
 
  return (
    <Fragment>
      <div className="my-task-list right-nav-section">
        <div className="dual-sec">
          <div className="page-heading">
            <h3>Task List</h3>
          </div>
          <div className="add-btn">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => navigate("/my-task-list/add-task/true")}
            >
              Add New Task
            </button>
          </div>
        </div>

        <div className="dual-exports">
          <div className="exprt-excel clickable">
            <DownloadTableExcel
              filename="users table"
              sheet="users"
              currentTableRef={tableRef.current}
            >
              <i class="fa-solid fa-file-excel 2px"></i>
            </DownloadTableExcel>
          </div>
          <div className="exprt-pdf clickable">
            <span onClick={() => toPDF()}>
              <i class="fa-solid fa-file-pdf"></i>
            </span>
          </div>
        </div>

        <Table striped bordered hover size="sm" ref={targetRef}>
          <thead className="thead-light">
            <tr>
              <th>Sr.No.</th>
              <th>Project</th>
              <th>Task</th>
              <th>Time Frame</th>
              <th>Assigned By</th>
              <th>Note</th>
              <th>Task Status</th>
              <th>Status</th>
              {/* <th>Actions</th> */}
            </tr>
          </thead>
          <tbody>
            {tasks &&
              tasks.map((task, index) => (
                <tr>
                  <td>{++index}</td>
                  <td>{task?.project_name}</td>
                  <td>{task?.task_title}</td>
                  <td>{getTimeFormat(task?.start_date)}-{getTimeFormat(task?.due_date)}</td>
                  <td>{task?.assign_by_first_name} {task?.assigned_by_last_name} <span className="badge due-date">{dueDateValidate(task?.due_date) ? "" : "Due task"}</span></td>
                  <td>{task?.note}</td>
                  <td><span className="badge">{task?.task_status_title}</span></td>
                  
                  <td onClick={() => openTaskStatusModal(task?.task_status_id, task?.task_title, task?.id)}><span className="badge clickable">{task?.task_status_title + " "}<i className="fas fa-edit"></i></span></td>
                  {/* <td>
                    <div className="inner-actions">
                      <div
                        className="edit-action"
                        // onClick={(e) => {
                        //   toEditPage(project?.id)
                        // }}
                      >
                        <i className="fas fa-edit"></i>
                      </div>

                      <div
                        className="trash-action"
                        // onClick={() => openDltModal(project?.id)}
                      >
                        <i className="fas fa-trash"></i>
                      </div>
                    </div>
                  </td> */}
                </tr>
              ))}
            {tasks.length == 0 ? <tr><td colSpan={5}>No tasks available</td></tr> : null}
          </tbody>
        </Table>
        <Table striped bordered hover size="sm" ref={tableRef} style={{ display: "none" }}>
          <thead className="thead-light">
            <tr>
              <th>Sr.No.</th>
              <th>Project</th>
              <th>Task</th>
              <th>Note</th>
              <th>Task Status</th>
              {/* <th>Status</th> */}
              {/* <th>Actions</th> */}
            </tr>
          </thead>
          <tbody>
            {tasks &&
              tasks.map((task, index) => (
                <tr>
                  <td>{++index}</td>
                  <td>{task?.project_name}</td>
                  <td>{task?.task_title}</td>
                  <td>{task?.note}</td>
                  <td>{task?.task_status_title}</td>
                </tr>
              ))}
          </tbody>
        </Table>






        <Modal
                show={taskStatusModal}
                onHide={() => setTaskStatusModal(false)}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Task's Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-6">
                        <div className="mb-3">
                            <label
                                for="exampleFormControlInput1"
                                className="form-label"
                            >
                                Select Status <span className="text-danger" style={{ "fontSize": "20px" }}>*</span>
                            </label>
                            
                            <select
                                className="form-select no-space"
                                aria-label="Default select example"
                                onChange={(e) => changeTaskStatus(e)}
                            >
                                <option disabled hidden>Select Task Status</option>
                                {taskStatusList &&
                                    taskStatusList.map((t_status) => (
                                        <option key={t_status?.id} value={t_status?.id} selected={t_status?.id == taskStatus?.selected_task_status}>
                                            {t_status?.task_status_title}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleTaskStatus()} disabled={isLoader}>
                        Update {isLoader && <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />}
                    </Button>
                </Modal.Footer>
            </Modal>




      </div>
    </Fragment>
  );
};

export default MyTaskList;
